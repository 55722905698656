<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HomepageNavigationItem',
  props: {
    imgUrl: String as PropType<string>,
    link: String as PropType<string>,
    text: String as PropType<string>,
  },
})
</script>

<template>
  <div class="w-35 flex-none pr-4 lg:w-1/10 md:w-2/12 sm:w-1/5">
    <div
      class="group aspect-w-1 aspect-h-1 relative overflow-hidden rounded-md bg-gray-100"
    >
      <img
        :src="imgUrl"
        alt="Model wearing minimalist watch with black wristband and white watch face."
        class="object-cover object-center"
      >
      <div class="flex flex-col justify-end">
        <div class="max-h-20 bg-black/50 p-x-4 p-y-2 text-sm -m-t-20">
          <NLink :href="link" class="text-sm text-white font-medium no-underline hover:text-white">
            <span class="absolute inset-0" aria-hidden="true" />
            {{ text }}
          </NLink>
        </div>
      </div>
    </div>
  </div>
</template>
