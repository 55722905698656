<script setup lang="ts">
const localePath = useLocalePath()
</script>

<template>
  <div>
    <div class="relative h-150 w-full pb-[33.3333%] transition-all duration-500 ease-in-out md:h-64">
      <div class="absolute inset-0 h-full w-full">
        <div class="relative h-full w-full">
          <div
            class="absolute top-0 h-full w-full flex flex-col justify-between overflow-hidden bg-cover px-4 bgi-[huglo_bckg.png] md:px-8"
          >
            <div class="h-full w-full flex flex-col items-center text-center md:flex-row">
              <div class="h-full w-full flex flex-col items-center justify-center md:basis-1/2">
                <h1 class="text-[4rem] text-white font-extrabold uppercase">
                  Rabaty nawet<br> <span>do 90%</span>
                </h1>
                <!-- <OfferCountdown>
                  <template #default="{ time }">
                    <div class="custom-time-display bg-black px-5">
                      <span class="text-[4rem] text-white md:text-[6rem]">{{ time.hours }}</span><span class="px-0.5 text-[4rem] text-white -mt-1 md:text-[6rem]">:</span>
                      <span class="text-[4rem] text-white md:text-[6rem]">{{ time.minutes }}</span><span class="px-0.5 text-[4rem] text-white -mt-1 md:text-[6rem]">:</span>
                      <span class="text-[4rem] text-white md:text-[6rem]">{{ time.seconds }}</span>
                    </div>
                  </template>
                </OfferCountdown> -->
              </div>
              <div class="hidden h-full w-full flex-col items-center justify-end md:flex md:basis-1/2">
                <img src="/huglo_pl_weron.png" alt="Huglo" class="w-150 -m-20">
              </div>
            </div>
            <div>
              <div class="flex flex-col items-center self-end justify-center flex-gap-4 pb-4 sm:flex-row" />
              <ContentGuard>
                <div
                  class="hidden flex-col items-center gap-y-2 bg-black/40 px-10 py-3 color-white -m-x-10 lg:flex lg:flex-row lg:justify-center lg:flex-gap-[15%]"
                >
                  <InlineWithIcon
                    icon-code="ph:gift"
                    icon-class="w-4 h-4"
                    class="font-bold"
                    :text="$t('trustable.gift_for_every_order')"
                  />
                  <InlineWithIcon
                    icon-code="ph:package"
                    icon-class="w-4 h-4"
                    class="font-bold"
                    :text="$t('trustable.express_24_shipping')"
                  />
                  <InlineWithIcon
                    icon-code="ph:truck"
                    class="font-bold"
                    icon-class="w-4 h-4"
                    :text="$t('trustable.fast_shipping')"
                  />
                  <InlineWithIcon
                    icon-code="ph:arrows-clockwise"
                    class="font-bold"
                    icon-class="w-4 h-4"
                    :text="$t('trustable.return_in_100_days')"
                  />
                </div>
              </ContentGuard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
